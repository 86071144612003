import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import PageTemplate from "../templates/PageTemplate";

const Page = ({ data, ...props }) => (
  <PageTemplate {...props} {..._get(data, "page")} />
);

export default Page;

export const query = graphql`
  query ($id: String) {
    page: topicPagesJson(id: { eq: $id }) {
      id
      seo {
        ...SEO
      }
      modularContent {
        ...ModularBlock
      }
    }
  }
`;
